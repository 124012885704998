import React from 'react';
import {Modal} from 'semantic-ui-react'
import {useDispatch} from "react-redux";
import {uiHideModal} from "../../../../common/ui-redux";
import LoginForm from "../LoginForm";
import {promiseListener} from "../../../../app/configureStore";
import {userTypes} from "../../duck";
import MakeAsyncFunction from "react-redux-promise-listener";

function UserLoginDialog() {

    const dispatch = useDispatch();

    const toggle = () => {
        dispatch(uiHideModal());
    };


    const cancelClicked = () => {
        dispatch(uiHideModal());
    };


    return (
        <Modal open={true} closeIcon={true} onClose={toggle}>
            <Modal.Header toggle={toggle}>Login</Modal.Header>
            <Modal.Content>
                <MakeAsyncFunction
                    listener={promiseListener}
                    start={userTypes.LOGIN_REQUEST}
                    resolve={userTypes.LOGIN_SUCCESS}
                    reject={userTypes.LOGIN_FAILURE}
                >{onSubmit => (
                    <LoginForm data={{}} saveCallback={onSubmit}/>
                )}
                </MakeAsyncFunction>
            </Modal.Content>
        </Modal>
    );
}

export default UserLoginDialog;
