import React, {useCallback, useRef, useState} from 'react';

import AvatarEditor from 'react-avatar-editor';
import {useDropzone} from 'react-dropzone';
import ReactSlider from "react-slider";

import './CharacterImageEditor.css';


function CharacterImageEditor({image, onChange}) {

    const [img, setImg] = useState(image);
    const [scale, setScale] = useState(1);
    const editor = useRef(null)

    const onDrop = useCallback(acceptedFiles => {
        setImg(acceptedFiles[0]);
    }, []);

    const {getRootProps, getInputProps, isDragActive, open,} = useDropzone({noClick: true, noKeyboard: true, onDrop})

    const onImageChange = () => {
        onChange(editor.current.getImageScaledToCanvas().toDataURL());
    };

    return (
        <div {...getRootProps()} style={{width: 300}}>
            <AvatarEditor
                ref={editor}
                width={250}
                height={250}
                image={img}
                scale={scale}
                onImageChange={onImageChange}
                onImageReady={onImageChange}
            />
            <input {...getInputProps()} />
            <ReactSlider
                className="horizontal-slider"
                thumbClassName="example-thumb"
                trackClassName="example-track"
                min={0.5}
                max={2}
                step={0.05}
                renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                onChange={(v, i) => setScale(v)}
                value={scale}
            />
            <button type="button" onClick={open}>
                Select an image
            </button>
        </div>
    );
}

export default CharacterImageEditor;