import {call, getContext, put, select, takeLatest} from 'redux-saga/effects';
import {profileUpdateBegin, profileUpdateFailure, profileUpdateSuccess,} from '../actions';

import types from '../types';

function* userInfo(action) {
  try {
    const userService = yield getContext('userService');

    yield put(profileUpdateBegin());
    const response = yield userService.profileUpdate(action.payload.profile);
    yield put(profileUpdateSuccess(action.payload.profile));

  } catch (e) {
    yield put(profileUpdateFailure(e.message));
  }
}

function* profileUpdateSaga() {
  yield takeLatest(types.PROFILE_UPDATE_REQUEST, userInfo);
}

export default profileUpdateSaga;
