import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    armorListRequest,
    characterClassListRequest,
    characterGetRequest, characterRemoveRequest,
    weaponListRequest
} from "../features/character/duck";
import {userSelectors} from "../features/user/duck";

function StoreInitialization(props) {

    const dispatch = useDispatch();

    const isUserLoggedIn = useSelector(userSelectors.isUserLoggedIn);

    useEffect(() => {
         // initialize references
        dispatch(characterClassListRequest());
        dispatch(armorListRequest());
        dispatch(weaponListRequest());
    }, []);


    useEffect(() => {
        if (isUserLoggedIn) {
            dispatch(characterGetRequest());
        }
        else {
            // reset user character on logout
            dispatch(characterRemoveRequest());
        }
    }, [isUserLoggedIn]);

    return (
        <></>
    );
}

export default StoreInitialization;
