import {put, takeLatest} from 'redux-saga/effects';
import {characterRemoveBegin, characterRemoveFailure, characterRemoveSuccess} from '../actions';

import types from '../types';

function* request(action) {
    try {
        yield put(characterRemoveBegin());
        yield put(characterRemoveSuccess());
    } catch (e) {
        yield put(characterRemoveFailure(e.message));
    }
}

function* addSaga() {
    yield takeLatest(types.REMOVE_REQUEST, request);
}

export default addSaga;
