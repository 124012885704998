import types from "./types";
import CharacterInfoDialog from "../../../features/character/components/dialogs/CharacterInfoDialog";
import MonsterInfoDialog from "../../../features/monster/components/dialogs/MonsterInfoDialog";
import UserCharacterDialog from "../../../features/character/components/dialogs/UserCharacterDialog";
import UserLoginDialog from "../../../features/user/components/dialogs/UserLoginDialog";
import UserRegistrationDialog from "../../../features/user/components/dialogs/UserRegistrationDialog";

const DIALOG_MAP = [];

DIALOG_MAP[types.CHARACTER_INFO] = CharacterInfoDialog;
DIALOG_MAP[types.MONSTER_INFO] = MonsterInfoDialog;
DIALOG_MAP[types.USER_CHARACTER] = UserCharacterDialog;
DIALOG_MAP[types.USER_LOGIN] = UserLoginDialog;
DIALOG_MAP[types.USER_REGISTER] = UserRegistrationDialog;

export default DIALOG_MAP;
