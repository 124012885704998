import types from './types';

//------------------
// MONSTER_LIST
//------------------
export const monsterListRequest = () => ({
  type: types.LIST_REQUEST,
});

export const monsterListBegin = () => ({
  type: types.LIST_BEGIN,
});

export const monsterListSuccess = (list) => ({
  type: types.LIST_SUCCESS,
  payload: {
    list,
  },
});

export const monsterListFailure = (error) => ({
  type: types.LIST_FAILURE,
  payload: {
    error,
  },
});
