import axios from "axios";
import {API_PATH} from "../../config";

export const login = (email, password) => {
    return axios.post(`${API_PATH}/auth/login/`, {
        email,
        password,
    });
};

export const logout = () => {
    return axios.post(`${API_PATH}/auth/logout/`);
};

export const registration = (email, password1, password2) => {
    return axios.post(`${API_PATH}/auth/registration/`, {
        email,
        password1,
        password2,
    });
};

export const tokenRefresh = (refresh) => {
    return axios.post(`${API_PATH}/auth/token/refresh/`, {
        refresh
    });
};

export const info = () => {
    return axios.get(`${API_PATH}/api/user/me/`);
};

export const avatarSave = (avatar) => {
    return axios.post(`${API_PATH}/api/profile/avatar`, {
        avatar
    });
};

export const profileUpdate = (profile) => {
    return axios.post(`${API_PATH}/api/profile/`, {
        ...profile
    });
};
