import {getContext, put, takeLatest} from 'redux-saga/effects';
import {characterCreateBegin, characterCreateFailure, characterCreateSuccess, characterListRequest,} from '../actions';
import types from "../types";

function* addGroup(action) {
  try {
    const characterService = yield getContext("characterService");

    yield put(characterCreateBegin());

    const addResponse = yield characterService.create(action.payload.character);

    yield put(characterCreateSuccess(addResponse.data));
    yield put(characterListRequest());
  } catch (e) {
    yield put(characterCreateFailure(e.message));
  }
}

function* createSaga() {
  yield takeLatest(types.CREATE_REQUEST, addGroup);
}

export default createSaga;
