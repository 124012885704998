import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import createSagaMiddleware from 'redux-saga';
import createReduxPromiseListener from 'redux-promise-listener'
import reducers from './reducers';
import mySaga from './sagas';

import * as characterService from '../common/api/characterService';
import * as monsterService from '../common/api/monsterService';
import * as battleService from '../common/api/battleService';
import * as userService from '../common/api/userService';
import * as promoService from '../common/api/promoService';
import {createAxiosAuthMiddleware} from "./axiosMiddleware";

const sagaMiddleware = createSagaMiddleware({
    context: {
        characterService,
        monsterService,
        battleService,
        userService,
        promoService,
    }
});

const axiosAuthMiddleware = createAxiosAuthMiddleware();
const reduxPromiseListener = createReduxPromiseListener();

export const promiseListener = reduxPromiseListener;

export default function configureStore(initialState = {}) {

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace: true, traceLimit: 25}) || compose;
    const store = createStore(
        combineReducers({
            ...reducers,
        }),
        initialState,
        composeEnhancers(applyMiddleware(sagaMiddleware, reduxPromiseListener.middleware), applyMiddleware(axiosAuthMiddleware)),
    );


    sagaMiddleware.run(mySaga);

    return {store};
}

