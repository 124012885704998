const CHARACTER_INFO = '/dialog/CHARACTER_INFO';
const MONSTER_INFO = '/dialog/MONSTER_INFO';
const USER_CHARACTER = '/dialog/USER_CHARACTER';
const USER_LOGIN = '/dialog/USER_LOGIN';
const USER_REGISTER = '/dialog/USER_REGISTER';

export default {
  CHARACTER_INFO,
  MONSTER_INFO,
  USER_CHARACTER,
  USER_LOGIN,
  USER_REGISTER,
}
