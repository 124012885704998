import {getContext, put, takeLatest} from 'redux-saga/effects';
import {characterClassListBegin, characterClassListFailure, characterClassListSuccess} from '../actions';

import types from '../types';

function* request(action) {
    try {
        const characterService = yield getContext("characterService");

        yield put(characterClassListBegin());
        const listResponse = yield characterService.getCharacterClasses();
        yield put(characterClassListSuccess(listResponse.data));
    } catch (e) {
        yield put(characterClassListFailure(e.message));
    }
}

function* characterClassListSaga() {
    yield takeLatest(types.CHARACTER_CLASS_LIST_REQUEST, request);
}

export default characterClassListSaga;
