function battleList(duck) {
  return duck.battle.list.list;
}

function battleStats(duck) {
  return duck.battle.list.beginningCombatStats;
}

function isBattleWon(duck) {
  return duck.battle.list.battleWon;
}

function isPromoActive(duck) {
  return duck.battle.list.promoActive;
}

function isBattleFinished(duck) {
  return duck.battle.list.battleFinished;
}

function battleDetail(duck) {
  return duck.battle.detail;
}

export default {
  battleList,
  battleDetail,
  battleStats,
  isBattleWon,
  isBattleFinished,
  isPromoActive,
}
