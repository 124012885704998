import React, {useEffect} from 'react';

import PersonageSelector from "../../../common/components/PersonageSelector";
import {monsterListRequest, monsterSelectors} from "../duck";
import {useDispatch, useSelector} from "react-redux";
import {uiShowModal} from "../../../common/ui-redux";
import {dialogTypes} from "../../../common/components/modalDialogs";


function MonsterSelector({onSelect}) {
    const dispatch = useDispatch();

    const list = useSelector(monsterSelectors.monsterList);

    useEffect(() => {
        dispatch(monsterListRequest());
    }, []);

    const onImageClick = (m) => {
        const monster = list.find((i) => i.id === m.id);
        dispatch(uiShowModal(dialogTypes.MONSTER_INFO, {monster}))
    }

    return (
        <PersonageSelector list={list} onSelect={onSelect}  onClick={onImageClick}/>
    );
}

export default MonsterSelector;
