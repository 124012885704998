import {getContext, put, takeLatest} from 'redux-saga/effects';
import {weaponListBegin, weaponListFailure, weaponListSuccess} from '../actions';

import types from '../types';

function* request(action) {
    try {
        const characterService = yield getContext("characterService");

        yield put(weaponListBegin());
        const listResponse = yield characterService.getWeapons();
        yield put(weaponListSuccess(listResponse.data));
    } catch (e) {
        yield put(weaponListFailure(e.message));
    }
}

function* weaponListSaga() {
    yield takeLatest(types.WEAPON_LIST_REQUEST, request);
}

export default weaponListSaga;
