import React, {useState} from 'react';
import {Button, Card, Confirm, Container, Grid, Header, Image} from "semantic-ui-react";
import {useDispatch, useSelector} from "react-redux";
import {userSelectors} from "../../features/user/duck";
import CheckUserLoginCard from "../../features/user/components/CheckUserLoginCard";
import {characterDeleteRequest, characterSelectors} from "../../features/character/duck";
import {useHistory} from "react-router-dom";
import CharacterEditForm from "../../features/character/components/CharacterEditForm";

function ProfilePage(props) {

    const isUserLoggedIn = useSelector(userSelectors.isUserLoggedIn);
    const character = useSelector(characterSelectors.userCharacter);
    const isCharacterCreated = useSelector(characterSelectors.isUserCharacterCreated);

    const classesList = useSelector(characterSelectors.characterClassList);
    const armorList = useSelector(characterSelectors.armorList);
    const weaponList = useSelector(characterSelectors.weaponList);

    const characterClass = classesList.find((i) => i.id === character.characterClass);
    const armor = armorList.find((i) => i.id === character.armor);
    const weapon = weaponList.find((i) => i.id === character.weapon);

    const history = useHistory();
    const dispatch = useDispatch();

    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

    const deleteCharacter = () => {
        dispatch(characterDeleteRequest());
        setDeleteConfirmOpen(false);
    };

    return (
        <>
            <Header as='h1'>User profile</Header>
            <CheckUserLoginCard message={"You need to login or register to see your profile"}/>
            {isUserLoggedIn && isCharacterCreated &&
            <Grid centered>
                <Grid.Column mobile={16} tabler={8} computer={8}>
                    <Card fluid centered>
                        <Card.Content>
                            <Card.Header>{character.name}</Card.Header>
                            <Card.Description>
                                <CharacterEditForm/>
                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                            <Button onClick={() => setDeleteConfirmOpen(true)}>Delete character</Button>
                            <Confirm
                                open={deleteConfirmOpen}
                                header='Delete your character?'
                                onCancel={() => setDeleteConfirmOpen(false)}
                                onConfirm={deleteCharacter}
                            />
                        </Card.Content>
                    </Card>
                </Grid.Column>
            </Grid>
            }
            {isUserLoggedIn && !isCharacterCreated &&
            <Header as='h2'>No character has been created yet</Header>
            }
            <Button onClick={() => history.push('/battle')}>Back to the battle</Button>
        </>

    );
}

export default ProfilePage;
