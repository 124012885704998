import types from './types';

//------------------
// BATTLE CHAT LIST
//------------------
export const battleListRequest = (character, monsterId) => ({
  type: types.LIST_REQUEST,
  payload: {
    character,
    monsterId,
  }
});

export const battleListBegin = () => ({
  type: types.LIST_BEGIN,
});

export const battleListSuccess = (beginningCombatStats, list, isPlayerWon, isPromoActive) => ({
  type: types.LIST_SUCCESS,
  payload: {
    beginningCombatStats,
    list,
    isPlayerWon,
    isPromoActive,
  },
});

export const battleListFailure = (error) => ({
  type: types.LIST_FAILURE,
  payload: {
    error,
  },
});

export const setBattleFinishedStatus = (status) => ({
  type: types.SET_BATTLE_FINISHED,
  payload: {
    status,
  },
});
