import types from './types';

export default function reducer(
    state = {
        selectedEntityId: '',
        modalType: null,
        modalProps: {},
    },
    action,
) {
    switch (action.type) {
        //------------------
        // Manage modal dialogs
        //------------------
        case types.SHOW_MODAL:
            return {
                ...state,
                modalType: action.payload.modalType,
                modalProps: action.payload.modalProps,
            };
        case types.HIDE_MODAL:
            return {
                ...state,
                modalType: null,
                modalProps: {},
            };
        default:
            break;
    }
    return state;
};
