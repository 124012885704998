import {call, getContext, put, select, takeLatest} from 'redux-saga/effects';
import {
  userLogoutBegin,
  userLogoutFailure,
  userLogoutSuccess,
} from '../actions';

import types from '../types';

function* logout(action) {
  try {
    const userService = yield getContext('userService');
    yield put(userLogoutBegin());
    const tokenResponse = yield userService.logout();
    yield put(userLogoutSuccess());
  } catch (e) {
    yield put(userLogoutSuccess());
    yield put(userLogoutFailure(e.message));
  }
}

function* logoutSaga() {
  yield takeLatest(types.LOGOUT_REQUEST, logout);
}

export default logoutSaga;
