import React from 'react';
import {Dropdown, Icon, Menu} from "semantic-ui-react";
import {useDispatch, useSelector} from "react-redux";
import {userLogoutRequest, userSelectors} from "../duck";
import LoginButton from "./LoginButton";
import UserInfo from "./UserInfo";
import {useHistory} from "react-router-dom";

function UserMenu(props) {

    const isUserLoggedIn = useSelector(userSelectors.isUserLoggedIn);
    const history = useHistory();

    const dispatch = useDispatch();

    const onLogoutClick = () => {
        dispatch(userLogoutRequest());
    }

    const onProfileClick = () => {
        history.push('/profile');
    }
    return (
        <Menu attached='bottom'>
            {isUserLoggedIn &&
            <>
                <Menu.Item position='right'>
                    <UserInfo/>
                </Menu.Item>
                <Dropdown item icon='user outline' upward direction={'left'}>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={onLogoutClick}><Icon name="external alternate"/> Logout</Dropdown.Item>
                        <Dropdown.Item onClick={onProfileClick}><Icon name="cog"/> Profile</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </>
            }
            {!isUserLoggedIn &&
            <>
                <LoginButton/>
            </>
            }
        </Menu>
    );
}

export default UserMenu;