import React from 'react';
import {useSelector} from "react-redux";
import {battleSelectors} from "../duck";
import {Segment} from "semantic-ui-react";

function BattleStat(props) {

    const stat = useSelector(battleSelectors.battleStats);

    return (
        <Segment>
            {stat && stat.map(line => (<div>{line}</div>))}
        </Segment>
    );
}

export default BattleStat;
