//---------------------
//  Manage modal dialogs
//---------------------
const SHOW_MODAL = '/ui/SHOW_MODAL';
const HIDE_MODAL = '/ui/HIDE_MODAL';

export default {
  SHOW_MODAL,
  HIDE_MODAL,
}
