import React from 'react';

import {uiSelectors} from "../../ui-redux";
import {dialogTypes} from "./index";
import DIALOG_MAP from "./dialogs";
import {useSelector} from "react-redux";

const ModalRoot = (props) => {
  const modalType = useSelector(uiSelectors.getModalType);
  const modalProps = useSelector(uiSelectors.getModalProps);

  if (!modalType) {
    return null;
  }

  const SpecificModal = DIALOG_MAP[modalType]
  return <SpecificModal {...modalProps} />
}

export default ModalRoot;
