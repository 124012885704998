const getModalType = (duck) => {
  return duck.ui.modalType;
}

const getModalProps = (duck) => {
  return duck.ui.modalProps;
}

export default {
  getModalType,
  getModalProps,
}
