import {getContext, put, takeLatest} from 'redux-saga/effects';
import {characterGetBegin, characterGetFailure, characterGetSuccess,} from '../actions';

import types from "../types";

function* fetchDetail(action) {
  try {
    const characterService = yield getContext("characterService");

    yield put(characterGetBegin());
    const detailResponse = yield characterService.get();
    yield put(characterGetSuccess(detailResponse.data));
  } catch (e) {
    yield put(characterGetFailure(e.message));
  }
}

function* getSaga() {
  yield takeLatest(types.GET_REQUEST, fetchDetail);
}

export default getSaga;
