import './App.css';

import {BrowserRouter} from 'react-router-dom';

import configureStore from './configureStore';
import Routes from './Routes';

import {Provider} from 'react-redux';
import StoreInitialization from "./StoreInitialization";

const {store} = configureStore();

function App() {

    return (
        <div>
            <Provider store={store}>
                <StoreInitialization/>
                <BrowserRouter basename="/">
                    {/*<ScrollToTop>*/}
                    <Routes/>
                    {/*</ScrollToTop>*/}
                </BrowserRouter>
            </Provider>
        </div>
    );
}

export default App;
