const LIST_REQUEST = '/features/character/LIST_REQUEST';
const LIST_BEGIN = '/features/character/LIST_BEGIN';
const LIST_SUCCESS = '/features/character/LIST_SUCCESS';
const LIST_FAILURE = '/features/character/LIST_FAILURE';

const CHARACTER_CLASS_LIST_REQUEST = '/features/character/CHARACTER_CLASS_LIST_REQUEST';
const CHARACTER_CLASS_LIST_BEGIN = '/features/character/CHARACTER_CLASS_LIST_BEGIN';
const CHARACTER_CLASS_LIST_SUCCESS = '/features/character/CHARACTER_CLASS_LIST_SUCCESS';
const CHARACTER_CLASS_LIST_FAILURE = '/features/character/CHARACTER_CLASS_LIST_FAILURE';

const ARMOR_LIST_REQUEST = '/features/character/ARMOR_LIST_REQUEST';
const ARMOR_LIST_BEGIN = '/features/character/ARMOR_LIST_BEGIN';
const ARMOR_LIST_SUCCESS = '/features/character/ARMOR_LIST_SUCCESS';
const ARMOR_LIST_FAILURE = '/features/character/ARMOR_LIST_FAILURE';


const WEAPON_LIST_REQUEST = '/features/character/WEAPON_LIST_REQUEST';
const WEAPON_LIST_BEGIN = '/features/character/WEAPON_LIST_BEGIN';
const WEAPON_LIST_SUCCESS = '/features/character/WEAPON_LIST_SUCCESS';
const WEAPON_LIST_FAILURE = '/features/character/WEAPON_LIST_FAILURE';

const ADD_REQUEST = '/features/character/ADD_REQUEST';
const ADD_BEGIN = '/features/character/ADD_BEGIN';
const ADD_SUCCESS = '/features/character/ADD_SUCCESS';
const ADD_FAILURE = '/features/character/ADD_FAILURE';

const REMOVE_REQUEST = '/features/character/REMOVE_REQUEST';
const REMOVE_BEGIN = '/features/character/REMOVE_BEGIN';
const REMOVE_SUCCESS = '/features/character/REMOVE_SUCCESS';
const REMOVE_FAILURE = '/features/character/REMOVE_FAILURE';

const CREATE_REQUEST = '/features/character/CREATE_REQUEST';
const CREATE_BEGIN = '/features/character/CREATE_BEGIN';
const CREATE_SUCCESS = '/features/character/CREATE_SUCCESS';
const CREATE_FAILURE = '/features/character/CREATE_FAILURE';

const GET_REQUEST = '/features/character/GET_REQUEST';
const GET_BEGIN = '/features/character/GET_BEGIN';
const GET_SUCCESS = '/features/character/GET_SUCCESS';
const GET_FAILURE = '/features/character/GET_FAILURE';

const UPDATE_REQUEST = '/features/character/UPDATE_REQUEST';
const UPDATE_BEGIN = '/features/character/UPDATE_BEGIN';
const UPDATE_SUCCESS = '/features/character/UPDATE_SUCCESS';
const UPDATE_FAILURE = '/features/character/UPDATE_FAILURE';

const DELETE_REQUEST = '/features/character/DELETE_REQUEST';
const DELETE_BEGIN = '/features/character/DELETE_BEGIN';
const DELETE_SUCCESS = '/features/character/DELETE_SUCCESS';
const DELETE_FAILURE = '/features/character/DELETE_FAILURE';

export default {
  LIST_REQUEST,
  LIST_BEGIN,
  LIST_SUCCESS,
  LIST_FAILURE,

  WEAPON_LIST_REQUEST,
  WEAPON_LIST_BEGIN,
  WEAPON_LIST_SUCCESS,
  WEAPON_LIST_FAILURE,

  ARMOR_LIST_REQUEST,
  ARMOR_LIST_BEGIN,
  ARMOR_LIST_SUCCESS,
  ARMOR_LIST_FAILURE,

  CHARACTER_CLASS_LIST_REQUEST,
  CHARACTER_CLASS_LIST_BEGIN,
  CHARACTER_CLASS_LIST_SUCCESS,
  CHARACTER_CLASS_LIST_FAILURE,

  ADD_REQUEST,
  ADD_BEGIN,
  ADD_SUCCESS,
  ADD_FAILURE,
  
  REMOVE_REQUEST,
  REMOVE_BEGIN,
  REMOVE_SUCCESS,
  REMOVE_FAILURE,
  
  CREATE_REQUEST,
  CREATE_BEGIN,
  CREATE_SUCCESS,
  CREATE_FAILURE,

  GET_REQUEST,
  GET_BEGIN,
  GET_SUCCESS,
  GET_FAILURE,

  UPDATE_REQUEST,
  UPDATE_BEGIN,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,

  DELETE_REQUEST,
  DELETE_BEGIN,
  DELETE_SUCCESS,
  DELETE_FAILURE,
};
