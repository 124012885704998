import types from '../types';

const initialState = {
    loading: false,
    list: [],
    error: null,
}
export default function reducer(
    state = {
        ...initialState,
    },
    action,
) {
    switch (action.type) {
        case types.LIST_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.LIST_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.LIST_SUCCESS:
            return {
                ...state,
                list: action.payload.list,
                loading: false,
                error: null,
            };
        case types.LIST_FAILURE:
            return {
                ...state,
                list: [],
                loading: false,
                error: action.payload.error,
            };
        default:
            break;
    }
    return state;
};
