import React, {lazy, Suspense, useEffect, useRef} from 'react';
import {Switch, Route, Redirect, useLocation, useHistory} from 'react-router-dom';
import {AnimatePresence, motion} from 'framer-motion';

// Layout Blueprints

import PresentationLayout from '../common/layout/PresentationLayout';

// Pages
import BattlePage from "./pages/BattlePage";
import ProfilePage from "./pages/ProfilePage";
import usePageTracking from "./usePageTracking";



const Routes = () => {
    const location = useLocation();
    usePageTracking();
    // const isLoggedIn = useSelector(userSelectors.isUserLoggedIn);
    const pageVariants = {
        initial: {
            opacity: 0,
            scale: 0.99
        },
        in: {
            opacity: 1,
            scale: 1
        },
        out: {
            opacity: 0,
            scale: 1.01
        }
    };

    const pageTransition = {
        type: 'tween',
        ease: 'anticipate',
        duration: 0.4
    };

    return (
        <AnimatePresence>
            <Suspense
                fallback={
                    <div
                        className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
                        <div className="w-50 mx-auto">
                            Please wait while we load the live preview examples
                        </div>
                    </div>
                }>
                <Switch>
                    <>
                        <Redirect exact from="/" to="/battle"/>
                        <Route path={[
                            '/battle',
                            '/profile',
                        ]}>
                            <PresentationLayout>
                                <Switch location={location} key={location.pathname}>
                                    {/*<motion.div*/}
                                    {/*  initial="initial"*/}
                                    {/*  animate="in"*/}
                                    {/*  exit="out"*/}
                                    {/*  variants={pageVariants}*/}
                                    {/*  transition={pageTransition}>*/}
                                    <Route path="/battle" component={BattlePage}/>
                                    <Route path="/profile" component={ProfilePage}/>
                                    {/*</motion.div>*/}
                                </Switch>
                            </PresentationLayout>
                        </Route>
                    </>
                </Switch>
            </Suspense>
        </AnimatePresence>
    );
};

export default Routes;
