import axios from "axios";
import {API_PATH} from "../../config";

export const systemFight = (characterId, monsterId) => {
    return axios.post(`${API_PATH}/api/battle/system_fight/`, {
        characterId,
        monsterId,
    });
};

export const customFight = (character, monsterId) => {
    return axios.post(`${API_PATH}/api/battle/custom_fight/`, {
        character: {
            characterClassId: character.characterClass,
            level: character.level,
            name: character.name,
            weaponId: character.weapon,
            armorId: character.armor,
            hp: character.hp,
        },
        monsterId,
    });
};
