import {all} from '@redux-saga/core/effects';
import loginSaga from './loginSaga';
import logoutSaga from './logoutSaga';
import tokenRefreshSaga from './tokenRefreshSaga';
import currentUserInfoSaga from "./currentUserInfoSaga";
import avatarSaveSaga from "./avatarSaveSaga";
import profileUpdateSaga from "./profileUpdateSaga";
import registerSaga from "./registerSaga";

export default function* rootSaga() {
    yield all([
        loginSaga(),
        logoutSaga(),
        tokenRefreshSaga(),
        currentUserInfoSaga(),
        avatarSaveSaga(),
        profileUpdateSaga(),
        registerSaga(),
    ]);
    // code after all-effect
}
