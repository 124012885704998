import React from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {promiseListener} from "../../../app/configureStore";
import MakeAsyncFunction from "react-redux-promise-listener";

import {promoTypes} from "../duck";
import PromoForm from "./PromoForm";

function PromoAddForm() {
    const history = useHistory();
    const dispatch = useDispatch();


    return (
        <MakeAsyncFunction
            listener={promiseListener}
            start={promoTypes.CREATE_REQUEST}
            resolve={promoTypes.CREATE_SUCCESS}
            reject={promoTypes.CREATE_FAILURE}
        >{onSubmit => (
            <PromoForm
                isEdit={false}
                data={{
                    name: '',
                    email: '',
                }}
                saveCallback={onSubmit}/>
        )}
        </MakeAsyncFunction>
    );
}

export default PromoAddForm;
