import {all} from '@redux-saga/core/effects';
import listSaga from './listSaga';
import characterClassListSaga from "./characterClassListSaga";
import armorListSaga from "./armorListSaga";
import weaponListSaga from "./weaponListSaga";
import addSaga from "./addSaga";
import createSaga from "./createSaga";
import getSaga from "./getSaga";
import deleteSaga from "./deleteSaga";
import removeSaga from "./removeSaga";
import updateSaga from "./updateSaga";

export default function* rootSaga() {
    yield all([
        listSaga(),
        characterClassListSaga(),
        armorListSaga(),
        weaponListSaga(),
        addSaga(),
        createSaga(),
        getSaga(),
        deleteSaga(),
        removeSaga(),
        updateSaga(),
    ]);
    // code after all-effect
}
