import {call, put, takeLatest, getContext} from 'redux-saga/effects';
import {
    currentUserInfoRequest,
    userLoginBegin,
    userLoginFailure,
    userLoginSuccess, userRegistrationBegin, userRegistrationFailure, userRegistrationSuccess,
} from '../actions';
import types from '../types';
import {registration} from "../../../../common/api/userService";


function* request(action) {
    try {
        const userService = yield getContext('userService');
        yield put(userRegistrationBegin());
        const tokenResponse = yield userService.registration(
            action.payload.email,
            action.payload.password1,
            action.payload.password2,
        );
        yield put(userRegistrationSuccess(
            tokenResponse.data.accessToken,
            tokenResponse.data.refreshToken,
            tokenResponse.data.user,
        ));
    } catch (e) {
        const fieldErrors = e.response?.data;
        yield put(userRegistrationFailure(e.message,  fieldErrors));
    }
}

function* registerSaga() {
    yield takeLatest(types.REGISTRATION_REQUEST, request);
}

export default registerSaga;
