import types from './types';

//------------------
// CHARACTER_LIST
//------------------
export const characterListRequest = () => ({
  type: types.LIST_REQUEST,
});

export const characterListBegin = () => ({
  type: types.LIST_BEGIN,
});

export const characterListSuccess = (list) => ({
  type: types.LIST_SUCCESS,
  payload: {
    list,
  },
});

export const characterListFailure = (error) => ({
  type: types.LIST_FAILURE,
  payload: {
    error,
  },
});

//------------------
// CHARACTER_CLASS_LIST
//------------------
export const characterClassListRequest = () => ({
  type: types.CHARACTER_CLASS_LIST_REQUEST,
});

export const characterClassListBegin = () => ({
  type: types.CHARACTER_CLASS_LIST_BEGIN,
});

export const characterClassListSuccess = (list) => ({
  type: types.CHARACTER_CLASS_LIST_SUCCESS,
  payload: {
    list,
  },
});

export const characterClassListFailure = (error) => ({
  type: types.CHARACTER_CLASS_LIST_FAILURE,
  payload: {
    error,
  },
});

//------------------
// ARMOR_LIST
//------------------
export const armorListRequest = () => ({
  type: types.ARMOR_LIST_REQUEST,
});

export const armorListBegin = () => ({
  type: types.ARMOR_LIST_BEGIN,
});

export const armorListSuccess = (list) => ({
  type: types.ARMOR_LIST_SUCCESS,
  payload: {
    list,
  },
});

export const armorListFailure = (error) => ({
  type: types.ARMOR_LIST_FAILURE,
  payload: {
    error,
  },
});

//------------------
// WEAPON_LIST
//------------------
export const weaponListRequest = () => ({
  type: types.WEAPON_LIST_REQUEST,
});

export const weaponListBegin = () => ({
  type: types.WEAPON_LIST_BEGIN,
});

export const weaponListSuccess = (list) => ({
  type: types.WEAPON_LIST_SUCCESS,
  payload: {
    list,
  },
});

export const weaponListFailure = (error) => ({
  type: types.WEAPON_LIST_FAILURE,
  payload: {
    error,
  },
});

//------------------
// CHARACTER_ADD
//------------------
export const characterAddRequest = (character) => ({
  type: types.ADD_REQUEST,
  payload: {
    character,
  }
});

export const characterAddBegin = () => ({
  type: types.ADD_BEGIN,
});

export const characterAddSuccess = (character) => ({
  type: types.ADD_SUCCESS,
  payload: {
    character,
  },
});

export const characterAddFailure = (error) => ({
  type: types.ADD_FAILURE,
  payload: {
    error,
  },
});

//------------------
// CHARACTER_REMOVE
//------------------
export const characterRemoveRequest = () => ({
  type: types.REMOVE_REQUEST,

});

export const characterRemoveBegin = () => ({
  type: types.REMOVE_BEGIN,
});

export const characterRemoveSuccess = () => ({
  type: types.REMOVE_SUCCESS,
});

export const characterRemoveFailure = (error) => ({
  type: types.REMOVE_FAILURE,
  payload: {
    error,
  },
});


//------------------
// CHARACTER DETAIL
//------------------
export const characterGetRequest = () => ({
  type: types.GET_REQUEST,
});

export const characterGetBegin = () => ({
  type: types.GET_BEGIN,
});

export const characterGetSuccess = (character) => ({
  type: types.GET_SUCCESS,
  payload: {
    character,
  },
});

export const characterGetFailure = (error) => ({
  type: types.GET_FAILURE,
  payload: {
    error,
  },
});

//------------------
// CHARACTER_CREATE
//------------------
export const characterCreateRequest = (character) => ({
  type: types.CREATE_REQUEST,
  payload: {
    character,
  }
});

export const characterCreateBegin = () => ({
  type: types.CREATE_BEGIN,
});

export const characterCreateSuccess = (character) => ({
  type: types.CREATE_SUCCESS,
  payload: {
    character,
  },
});

export const characterCreateFailure = (error) => ({
  type: types.CREATE_FAILURE,
  payload: {
    error,
  },
});

//------------------
// CHARACTER EDIT
//------------------
export const characterUpdateRequest = (character) => ({
  type: types.UPDATE_REQUEST,
  payload: {
    character
  },
});

export const characterUpdateBegin = () => ({
  type: types.UPDATE_BEGIN,
});

export const characterUpdateSuccess = (character) => ({
  type: types.UPDATE_SUCCESS,
  payload: {
    character
  }
});

export const characterUpdateFailure = (error) => ({
  type: types.UPDATE_FAILURE,
  payload: {
    error,
  },
});


//------------------
// CHARACTER DELETE
//------------------
export const characterDeleteRequest = () => ({
  type: types.DELETE_REQUEST,
});

export const characterDeleteBegin = () => ({
  type: types.DELETE_BEGIN,
});

export const characterDeleteSuccess = () => ({
  type: types.DELETE_SUCCESS,
});

export const characterDeleteFailure = (error) => ({
  type: types.DELETE_FAILURE,
  payload: {
    error,
  },
});
