import battle from '../features/battle/duck';
import character from '../features/character/duck';
import monster from '../features/monster/duck';
import ui from '../common/ui-redux';
import user from '../features/user/duck';
import promo from '../features/promo/duck';

export default {
    battle,
    character,
    monster,
    ui,
    user,
    promo,
};
