import {call, getContext, put, takeLatest} from 'redux-saga/effects';
import {
  userTokenRefreshBegin,
  userTokenRefreshFailure,
  userTokenRefreshSuccess,
} from '../actions';

import types from '../types';



function* userTokenRefresh(action) {
  try {
    const userService = yield getContext('userService');

    yield put(userTokenRefreshBegin());
    const tokenResponse = yield userService.tokenRefresh();
    yield put(userTokenRefreshSuccess(tokenResponse.data.data.jwt));
  } catch (e) {
    yield put(userTokenRefreshFailure(e.message));
  }
}

function* userTokenRefreshSaga() {
  yield takeLatest(types.TOKEN_REFRESH_REQUEST, userTokenRefresh);
}

export default userTokenRefreshSaga;
