import React, {useEffect, useState} from 'react';

import PersonageSelector from "../../../common/components/PersonageSelector";
import {characterListRequest, characterSelectors} from "../duck";
import {useDispatch, useSelector} from "react-redux";

import {uiShowModal} from "../../../common/ui-redux";
import {dialogTypes} from "../../../common/components/modalDialogs";
import {Button, Grid, GridRow} from "semantic-ui-react";
import createCharacterImage from '../assets/add_character.png';

function CharacterSelector({onSelect}) {
    const dispatch = useDispatch();
    const list = useSelector(characterSelectors.characterList);
    const isUserCharacterCreated = useSelector(characterSelectors.isUserCharacterCreated);
    const userCharacter = useSelector(characterSelectors.userCharacter);

    const [displayList, setDisplayList] = useState([]);

    useEffect(() => {
        dispatch(characterListRequest());
    }, []);

    useEffect(() => {
        const preparedList = list.map((i) => ({type: 'system', ...i}));

        if (isUserCharacterCreated) {
            setDisplayList([{type: 'user', ...userCharacter}, ...preparedList]);
        } else {
            const createCharacterSlide = {
                id: 'create-character',
                type: 'user',
                name: 'Create character',
                image: createCharacterImage,
            }
            setDisplayList([...preparedList, createCharacterSlide]);
        }
    }, [list, isUserCharacterCreated, userCharacter]);

    const onImageClick = (c) => {
        let character = null;
        if (c.id === 'user-unsaved') {
            character = userCharacter;
        } else if (c.id === 'create-character') {
            createCharacter();
            return;
        } else {
            character = c;
        }

        dispatch(uiShowModal(dialogTypes.CHARACTER_INFO, {character}))
    }

    const createCharacter = () => {
        dispatch(uiShowModal(dialogTypes.USER_CHARACTER))
    }

    return (
        <div style={{flexDirection: "column"}}>
            <PersonageSelector list={displayList} onSelect={onSelect} onClick={onImageClick}/>
            {/*todo: keep it for testing purposes*/}
            {/*{!isUserCharacterCreated &&*/}
            {/*<div style={{marginTop: "20px"}}>*/}
            {/*    <Button onClick={createCharacter}>Create your own character</Button>*/}
            {/*</div>*/}
            {/*}*/}
        </div>
    );
}

export default CharacterSelector;
