import types from './types';

//------------------
// PROMO CREATE
//------------------
export const promoCreateRequest = (promo) => ({
  type: types.CREATE_REQUEST,
  payload: {
    promo,
  }
});

export const promoCreateBegin = () => ({
  type: types.CREATE_BEGIN,
});

export const promoCreateSuccess = (promo) => ({
  type: types.CREATE_SUCCESS,
  payload: {
    promo,
  },
});

export const promoCreateFailure = (error, fieldErrors) => ({
  type: types.CREATE_FAILURE,
  payload: {
    error,
    fieldErrors,
  },
});

