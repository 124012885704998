import {call, getContext, put, select, takeLatest} from 'redux-saga/effects';
import {avatarSaveBegin, avatarSaveFailure, avatarSaveSuccess,} from '../actions';

import types from '../types';

function* userInfo(action) {
  try {
    const userService = yield getContext('userService');

    yield put(avatarSaveBegin());
    const response = yield userService.avatarSave(action.payload.avatar);
    yield put(avatarSaveSuccess(action.payload.avatar));

  } catch (e) {
    yield put(avatarSaveFailure(e.message));
  }
}

function* avatarSaveSaga() {
  yield takeLatest(types.AVATAR_SAVE_REQUEST, userInfo);
}

export default avatarSaveSaga;
