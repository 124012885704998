import {call, put, select, takeLatest, getContext} from 'redux-saga/effects';
import {
    characterListBegin, characterListSuccess, characterListFailure
} from '../actions';

import types from '../types';

function* request(action) {
    try {
        const characterService = yield getContext("characterService");

        yield put(characterListBegin());
        const listResponse = yield characterService.getAll();
        yield put(characterListSuccess(listResponse.data));
    } catch (e) {
        yield put(characterListFailure(e.message));
    }
}

function* listSaga() {
    yield takeLatest(types.LIST_REQUEST, request);
}

export default listSaga;
