import {call, getContext, put, select, takeLatest} from 'redux-saga/effects';
import {currentUserInfoBegin, currentUserInfoFailure, currentUserInfoSuccess,} from '../actions';

import types from '../types';

function* userInfo(action) {
  try {
    const userService = yield getContext('userService');

    yield put(currentUserInfoBegin());
    const response = yield userService.info();
    yield put(currentUserInfoSuccess(response.data));

  } catch (e) {
    yield put(currentUserInfoFailure(e.message));
  }
}

function* currentUserInfoSaga() {
  yield takeLatest(types.CURRENT_USER_INFO_REQUEST, userInfo);
}

export default currentUserInfoSaga;
