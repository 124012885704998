import types from './types';

export default function reducer(
    state = {
        loading: false,
        token: '',
        refreshToken: '',
        user: {},
        isLoggedIn: false,
        error: null,
    },
    action,
) {
    switch (action.type) {
        //------------------
        // USER LOGIN
        //------------------
        case types.LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.LOGIN_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.LOGIN_SUCCESS:
            return {
                ...state,
                token: action.payload.token,
                refreshToken: action.payload.refresh,
                user: action.payload.user,
                isLoggedIn: true,
                loading: false,
                error: null,
            };
        case types.LOGIN_FAILURE:
            return {
                ...state,
                token: '',
                loading: false,
                error: action.payload.error,
            };

        //------------------
        // USER REGISTRATION
        //------------------
        case types.REGISTRATION_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.REGISTRATION_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.REGISTRATION_SUCCESS:
            return {
                ...state,
                token: action.payload.token,
                refreshToken: action.payload.refresh,
                user: action.payload.user,
                isLoggedIn: true,
                loading: false,
                error: null,
            };
        case types.REGISTRATION_FAILURE:
            return {
                ...state,
                token: '',
                loading: false,
                error: action.payload.error,
            };
        //------------------
        // USER TOKEN REFRESH
        //------------------
        case types.TOKEN_REFRESH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.TOKEN_REFRESH_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.TOKEN_REFRESH_SUCCESS:
            return {
                ...state,
                token: action.payload.token,
                loading: false,
                error: null,
            };
        case types.TOKEN_REFRESH_FAILURE:
            return {
                ...state,
                token: '',
                isLoggedIn: false,
                loading: false,
                error: action.payload.error,
            };

        //------------------
        // USER LOGOUT
        //------------------
        case types.LOGOUT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.LOGOUT_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.LOGOUT_SUCCESS:
            return {
                ...state,
                token: '',
                refreshToken: '',
                isLoggedIn: false,
                loading: false,
                error: null,
            };
        case types.LOGOUT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        //------------------
        // CURRENT USER INFO
        //------------------
        case types.CURRENT_USER_INFO_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.CURRENT_USER_INFO_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.CURRENT_USER_INFO_SUCCESS:
            return {
                ...state,
                user: action.payload.user,
                loading: false,
                error: null,
            };
        case types.CURRENT_USER_INFO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case types.AVATAR_SAVE_REQUEST:
            return {
                ...state,
                loading: true,
                user: {
                    ...state.user,
                    avatar: action.payload.avatar
                },
            };
        case types.AVATAR_SAVE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.AVATAR_SAVE_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case types.AVATAR_SAVE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case types.PROFILE_UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
                user: {
                    ...state.user,
                    ...action.payload.profile
                },
            };
        case types.PROFILE_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.PROFILE_UPDATE_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case types.PROFILE_UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        default:
            break;
    }
    return state;
}
