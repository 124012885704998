import types from '../types';

const initialState = {
    loading: false,
    character: {},
    isModified: false,
    isCreated: false,
    error: null,
}
export default function reducer(
    state = {
        ...initialState,
    },
    action,
) {
    switch (action.type) {
        case types.ADD_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.ADD_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.ADD_SUCCESS:
            return {
                ...state,
                character: action.payload.character,
                isCreated: true,
                isModified: true,
                loading: false,
                error: null,
            };

        case types.REMOVE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.REMOVE_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.REMOVE_SUCCESS:
            return {
                ...state,
                character: {},
                isCreated: false,
                isModified: false,
                loading: false,
                error: null,
            };
        //----------
        // CREATE
        //----------
        case types.CREATE_REQUEST:
            return {
                ...state,
                error: null,
            };
        case types.CREATE_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.CREATE_SUCCESS:
            return {
                ...state,
                character: action.payload.character,
                isCreated: true,
                isModified: false,
                loading: false,
                error: null,
            };
        case types.CREATE_FAILURE:
            return {
                ...state,
                id: '',
                name: '',
                groupId: '',
                loading: false,
                error: action.payload.error,
            };
        //-----------
        // GET DETAIL
        //-----------
        case types.GET_REQUEST:
            return {
                ...state,
                error: null,
            };
        case types.GET_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                character: action.payload.character,
                isCreated: true,
                isModified: false,
                loading: false,
                error: null,
            };
        case types.GET_FAILURE:
            return {
                ...state,
                id: '',
                name: '',
                groupId: '',
                loading: false,
                error: action.payload.error,
            };
        //----------
        // EDIT
        //----------
        case types.UPDATE_REQUEST:
            return {
                ...state,
                error: null,
            };
        case types.UPDATE_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.UPDATE_SUCCESS:
            return {
                ...state,
                character: action.payload.character,
                loading: false,
                error: null,
            };
        case types.UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        //----------
        // DELETE
        //----------
        case types.DELETE_REQUEST:
            return {
                ...state,
                error: null,
            };
        case types.DELETE_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.DELETE_SUCCESS:
            return {
                ...state,
                character: {},
                isCreated: false,
                isModified: false,
                loading: false,
                error: null,
            };
        case types.DELETE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        default:
            break;
    }
    return state;
};
