import React from 'react';
import {Button, Form, Input} from "semantic-ui-react";
import {Controller, useForm} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";

function PromoForm({data, saveCallback}) {
    const {register, control, handleSubmit, trigger, watch, formState: {errors}, setError, reset, setValue} = useForm({
        defaultValues: data,
    });

     const onFormSubmit = async (data) => {
        try {
            const result = await saveCallback({promo: data});
        } catch (e) {
            const detailFieldErrors = e.fieldErrors;

            for (let item in detailFieldErrors) {
                if (detailFieldErrors.hasOwnProperty(item)) {
                    setError(item, {
                        type: 'server',
                        message: detailFieldErrors[item].join('. ')
                    })
                }
            }
        }
    };

    return (
        <Form onSubmit={handleSubmit(async (data) => await onFormSubmit(data))}>
            <Form.Field>
                <Controller
                    name={"name"}
                    rules={{required: true}}
                    control={control}
                    render={({field: {onChange, onBlur, value, ref}}) => (
                        <Input type="text"
                               label="Name"
                               onChange={onChange}
                               onBlur={onBlur}
                               defaultValue={value}
                               placeholder="Name"
                               error={errors.name ? true : false}
                        />
                    )}
                />
                <ErrorMessage errors={errors} name="name"/>
            </Form.Field>
            <Form.Field>
                <Controller
                    name={"email"}
                    rules={{required: true}}
                    control={control}
                    render={({field: {onChange, onBlur, value, ref}}) => (
                        <Input type="text"
                               label="Email"
                               onChange={onChange}
                               onBlur={onBlur}
                               defaultValue={value}
                               placeholder="Email"
                               error={errors.email ? true : false}
                        />
                    )}
                />
                <ErrorMessage errors={errors} name="email"/>
            </Form.Field>
            <Button data-testid="formPromoSubmit" className="float-right">Send</Button>
        </Form>
    );
}

export default PromoForm;
