const CREATE_REQUEST = '/features/promo/CREATE_REQUEST';
const CREATE_BEGIN = '/features/promo/CREATE_BEGIN';
const CREATE_SUCCESS = '/features/promo/CREATE_SUCCESS';
const CREATE_FAILURE = '/features/promo/CREATE_FAILURE';

export default {
  CREATE_REQUEST,
  CREATE_BEGIN,
  CREATE_SUCCESS,
  CREATE_FAILURE,
};
