import {call, put, takeLatest, getContext} from 'redux-saga/effects';
import {
    currentUserInfoRequest,
    userLoginBegin,
    userLoginFailure,
    userLoginSuccess,
} from '../actions';
import types from '../types';


function* login(action) {
    try {

        const userService = yield getContext('userService');
        yield put(userLoginBegin());
        const tokenResponse = yield userService.login(
            action.payload.email,
            action.payload.password,
        );
        yield put(userLoginSuccess(
            tokenResponse.data.accessToken,
            tokenResponse.data.refreshToken,
            tokenResponse.data.user,
        ));
    } catch (e) {
         const fieldErrors = e.response?.data;
        yield put(userLoginFailure(e.message,  fieldErrors));
    }
}

function* loginSaga() {
    yield takeLatest(types.LOGIN_REQUEST, login);
}

export default loginSaga;
