function isPromoLoading(duck) {
  return duck.promo.detail.loading;
}

function isPromoSent(duck) {
  return duck.promo.detail.promoSent;
}

function promoError(duck) {
  return duck.promo.detail.error;
}

export default {
  isPromoLoading,
  promoError,
  isPromoSent,
}
