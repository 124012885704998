const LIST_REQUEST = '/features/monster/LIST_REQUEST';
const LIST_BEGIN = '/features/monster/LIST_BEGIN';
const LIST_SUCCESS = '/features/monster/LIST_SUCCESS';
const LIST_FAILURE = '/features/monster/LIST_FAILURE';


export default {
  LIST_REQUEST,
  LIST_BEGIN,
  LIST_SUCCESS,
  LIST_FAILURE,
};
