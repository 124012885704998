//------------------
// USER LOGIN
//------------------
import types from './types';

export const userLoginRequest = (email, password) => ({
  type: types.LOGIN_REQUEST,
  payload: {
    email,
    password,
  },
});

export const userLoginBegin = () => ({
  type: types.LOGIN_BEGIN,
});

export const userLoginSuccess = (token, refresh, user) => ({
  type: types.LOGIN_SUCCESS,
  payload: {
    token,
    refresh,
    user,
  },
});

export const userLoginFailure = (error,  fieldErrors) => ({
  type: types.LOGIN_FAILURE,

  payload: {
    error,
    fieldErrors,
  },
});

//------------------
// USER REGISTER
//------------------

export const userRegistrationRequest = (email, password1, password2) => ({
  type: types.REGISTRATION_REQUEST,
  payload: {
    email,
    password1,
    password2,
  },
});

export const userRegistrationBegin = () => ({
  type: types.REGISTRATION_BEGIN,
});

export const userRegistrationSuccess = (token, refresh, user) => ({
  type: types.REGISTRATION_SUCCESS,
  payload: {
    token,
    refresh,
    user,
  },
});

export const userRegistrationFailure = (error, fieldErrors) => ({
  type: types.REGISTRATION_FAILURE,

  payload: {
    error,
    fieldErrors,
  },
});

//------------------
// USER TOKEN REFRESH
//------------------


export const userTokenRefreshRequest = (token) => ({
  type: types.TOKEN_REFRESH_REQUEST,
  payload: {
    token,
  },
});

export const userTokenRefreshBegin = () => ({
  type: types.TOKEN_REFRESH_BEGIN,
});

export const userTokenRefreshSuccess = (token) => ({
  type: types.TOKEN_REFRESH_SUCCESS,
  payload: {
    token,
  },
});

export const userTokenRefreshFailure = (error) => ({
  type: types.TOKEN_REFRESH_FAILURE,
  payload: {
    error,
  },
});

//------------------
// USER LOGOUT
//------------------

export const userLogoutRequest = () => ({
  type: types.LOGOUT_REQUEST,
});

export const userLogoutBegin = () => ({
  type: types.LOGOUT_BEGIN,
});

export const userLogoutSuccess = () => ({
  type: types.LOGOUT_SUCCESS,
  payload: {
    token: '',
    user: {},
  },
});

export const userLogoutFailure = (error) => ({
  type: types.LOGOUT_FAILURE,
  payload: {
    error,
  },
});

//-----------------
// CURRENT USER INFO
//-----------------
export const currentUserInfoRequest = () => ({
  type: types.CURRENT_USER_INFO_REQUEST,
});

export const currentUserInfoBegin = () => ({
  type: types.CURRENT_USER_INFO_BEGIN,
});

export const currentUserInfoSuccess = (user) => ({
  type: types.CURRENT_USER_INFO_SUCCESS,
  payload: {
    user,
  },
});

export const currentUserInfoFailure = (error) => ({
  type: types.CURRENT_USER_INFO_FAILURE,
  payload: {
    error,
  },
});


//-----------------
// AVATAR SAVE
//-----------------
export const avatarSaveRequest = (avatar) => ({
  type: types.AVATAR_SAVE_REQUEST,
  payload: {
    avatar,
  }
});

export const avatarSaveBegin = () => ({
  type: types.AVATAR_SAVE_BEGIN,
});

export const avatarSaveSuccess = (user) => ({
  type: types.AVATAR_SAVE_SUCCESS,
  payload: {
    user,
  },
});

export const avatarSaveFailure = (error) => ({
  type: types.AVATAR_SAVE_FAILURE,
  payload: {
    error,
  },
});

//-----------------
// profile update
//-----------------
export const profileUpdateRequest = (avatar) => ({
  type: types.PROFILE_UPDATE_REQUEST,
  payload: {
    avatar,
  }
});

export const profileUpdateBegin = () => ({
  type: types.PROFILE_UPDATE_BEGIN,
});

export const profileUpdateSuccess = (user) => ({
  type: types.PROFILE_UPDATE_SUCCESS,
  payload: {
    user,
  },
});

export const profileUpdateFailure = (error) => ({
  type: types.PROFILE_UPDATE_FAILURE,
  payload: {
    error,
  },
});
