import React from 'react';
import {Modal} from 'semantic-ui-react'
import {useDispatch} from "react-redux";
import {uiHideModal} from "../../../../common/ui-redux";
import LoginForm from "../LoginForm";
import RegistrationForm from "../RegistrationForm";
import {promiseListener} from "../../../../app/configureStore";
import {characterTypes} from "../../../character/duck";
import MakeAsyncFunction from "react-redux-promise-listener";
import {userTypes} from "../../duck";

function UserRegistrationDialog() {

    const dispatch = useDispatch();

    const toggle = () => {
        dispatch(uiHideModal());
    };


    const cancelClicked = () => {
        dispatch(uiHideModal());
    };


    return (
        <Modal open={true} closeIcon={true} onClose={toggle}>
            <Modal.Header toggle={toggle}>Registration</Modal.Header>
            <Modal.Content>
                <MakeAsyncFunction
                    listener={promiseListener}
                    start={userTypes.REGISTRATION_REQUEST}
                    resolve={userTypes.REGISTRATION_SUCCESS}
                    reject={userTypes.REGISTRATION_FAILURE}
                >{onSubmit => (
                    <RegistrationForm data={{}} saveCallback={onSubmit}/>
                )}
                </MakeAsyncFunction>
            </Modal.Content>
            {/*<Modal.Actions>*/}
            {/*    <Button color="olive" onClick={cancelClicked}>Return</Button>*/}
            {/*</Modal.Actions>*/}
        </Modal>
    );
}

export default UserRegistrationDialog;
