import React from 'react';
import {Button, Modal} from 'semantic-ui-react'
import {useDispatch} from "react-redux";
import {uiHideModal} from "../../../../common/ui-redux";
import CharacterAddForm from "../CharacterAddForm";

function UserCharacterDialog() {

    const dispatch = useDispatch();

    const toggle = () => {
        dispatch(uiHideModal());
    };


    const cancelClicked = () => {
        dispatch(uiHideModal());
    };

    return (
        <Modal open={true} closeIcon={true} onClose={toggle}>
            <Modal.Header toggle={toggle}>Create Your Own Character</Modal.Header>
            <Modal.Content>
                <CharacterAddForm />
            </Modal.Content>
            <Modal.Actions>
                <Button color="olive" onClick={cancelClicked}>Return</Button>
            </Modal.Actions>
        </Modal>
    );
}

export default UserCharacterDialog;
