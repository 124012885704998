import types from '../types';

const initialState = {
    loading: false,
    promoSent: false,
    promo: {},
    error: null,
}
export default function reducer(
    state = {
        ...initialState,
    },
    action,
) {
    switch (action.type) {
        //----------
        // CREATE
        //----------
        case types.CREATE_REQUEST:
            return {
                ...state,
                promoSent: false,
                error: null,
            };
        case types.CREATE_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.CREATE_SUCCESS:
            return {
                ...state,
                promo: action.payload.promo,
                promoSent: true,
                loading: false,
                error: null,
            };
        case types.CREATE_FAILURE:
            return {
                ...state,
                id: '',
                name: '',
                email: '',
                promoSent: false,
                loading: false,
                error: action.payload.error,
            };
        default:
            break;
    }
    return state;
};
