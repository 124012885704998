import {getContext, put, takeLatest} from 'redux-saga/effects';
import {promoCreateBegin, promoCreateFailure, promoCreateSuccess,} from '../actions';
import types from "../types";
import {userLoginFailure} from "../../../user/duck";

function* create(action) {
  try {
    const promoService = yield getContext("promoService");

    yield put(promoCreateBegin());

    const addResponse = yield promoService.create(action.payload.promo);

    yield put(promoCreateSuccess(addResponse.data));
  } catch (e) {
    const fieldErrors = e.response?.data;
    yield put(promoCreateFailure(e.message, fieldErrors));
  }
}

function* createSaga() {
  yield takeLatest(types.CREATE_REQUEST, create);
}

export default createSaga;
