import types from "./types";

export const uiShowModal = (modalType, modalProps) => ({
  type: types.SHOW_MODAL,
  payload: {
    modalType,
    modalProps,
  },
});

export const uiHideModal = () => ({
  type: types.HIDE_MODAL,
});
