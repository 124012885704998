import {call, put, select, takeLatest, getContext} from 'redux-saga/effects';
import {
    battleListBegin, battleListSuccess, battleListFailure
} from '../actions';

import types from '../types';

function* request(action) {
    try {
        const battleService = yield getContext("battleService");
        let listResponse = null;
        yield put(battleListBegin());
        if (action.payload.character.id === 'user-unsaved') {
            listResponse = yield battleService.customFight(action.payload.character, action.payload.monsterId);
        }
        else {
            listResponse = yield battleService.systemFight(action.payload.character.id, action.payload.monsterId);
        }
        yield put(battleListSuccess(
            listResponse.data.beginningCombatStats,
            listResponse.data.battleChat,
            listResponse.data.isPlayerWon,
            listResponse.data.showPromoForm,
        ));
    } catch (e) {
        yield put(battleListFailure(e.message));
    }
}

function* listSaga() {
    yield takeLatest(types.LIST_REQUEST, request);
}

export default listSaga;
