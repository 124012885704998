import {getContext, put, takeLatest} from 'redux-saga/effects';
import {characterUpdateBegin, characterUpdateFailure, characterUpdateSuccess, characterListRequest,} from '../actions';
import types from "../types";
import {setBattleFinishedStatus} from "../../../battle/duck";

function* request(action) {
  try {
    const characterService = yield getContext("characterService");

    yield put(characterUpdateBegin());

    const editResponse = yield characterService.update(
        action.payload.character.armor,
        action.payload.character.weapon,
        action.payload.character.description,
        action.payload.character.socialProfile,
    );

    yield put(characterUpdateSuccess(editResponse.data));
    yield put(setBattleFinishedStatus(false)); // NB! Breaks features isolation
  } catch (e) {
    yield put(characterUpdateFailure(e.message));
  }
}

function* updateSaga() {
  yield takeLatest(types.UPDATE_REQUEST, request);
}

export default updateSaga;
