import {put, takeLatest} from 'redux-saga/effects';
import {characterAddBegin, characterAddFailure, characterAddSuccess} from '../actions';

import types from '../types';
import {setBattleFinishedStatus} from "../../../battle/duck";

function* request(action) {
    try {
        yield put(characterAddBegin());
        yield put(characterAddSuccess(action.payload.character));
        yield put(setBattleFinishedStatus(false)); // NB! Breaks features isolation

    } catch (e) {
        yield put(characterAddFailure(e.message));
    }
}

function* addSaga() {
    yield takeLatest(types.ADD_REQUEST, request);
}

export default addSaga;
