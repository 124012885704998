const LIST_REQUEST = '/features/battle/LIST_REQUEST';
const LIST_BEGIN = '/features/battle/LIST_BEGIN';
const LIST_SUCCESS = '/features/battle/LIST_SUCCESS';
const LIST_FAILURE = '/features/battle/LIST_FAILURE';

const SET_BATTLE_FINISHED = '/features/battle/SET_BATTLE_FINISHED';

export default {
  LIST_REQUEST,
  LIST_BEGIN,
  LIST_SUCCESS,
  LIST_FAILURE,

  SET_BATTLE_FINISHED,
};
