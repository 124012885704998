import React, {useEffect, useState} from 'react';
import {Grid, GridRow, List, Progress, Segment, Transition} from 'semantic-ui-react'
import {useDispatch, useSelector} from "react-redux";
import {battleSelectors, setBattleFinishedStatus, setBattleWonStatus} from "../duck";

function BattleLog({character, monster}) {
    const [items, setItems] = useState([]);
    const [round, setRound] = useState(0);
    const [characterHP, setCharacterHP] = useState(0);
    const [monsterHP, setMonsterHP] = useState(0);

    const messages = useSelector(battleSelectors.battleList);

    const dispatch = useDispatch();

    const animateBattle = () => {
        if (items.length !== messages.length) {
            const currentLog = messages[items.length];
            setItems(items.concat([currentLog]));
            setRound(currentLog.round);
            setCharacterHP(currentLog.characterHp);
            setMonsterHP(currentLog.monsterHp);
        }
        else {
            dispatch(setBattleFinishedStatus(true));
        }
    }

    useEffect(() => {
        setItems([]);
    }, [messages]);

    useEffect(() => {
        setTimeout(animateBattle, 1000);
    }, [items]);


    // list of available animations https://react.semantic-ui.com/modules/transition/#types-group
    return (
        <>
            <Grid className={'wide'}>
                <Grid.Row columns={1} centered>
                    <Segment>Round: {round}</Segment>
                </Grid.Row>
                <Grid.Row columns={2} centered stretched>
                    <Grid.Column>
                        <Progress color='red'
                             total={character.hp}
                            value={characterHP}
                        >
                            {character.name}: {characterHP}
                        </Progress>
                    </Grid.Column>
                    <Grid.Column>
                        <Progress color='red'
                            total={monster.hp}
                            value={monsterHP}
                        >
                            {monster.name}: {monsterHP}
                        </Progress>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Transition.Group
                        as={List}
                        duration={300}
                        divided
                        size='huge'
                        verticalAlign='middle'
                        animation='zoom'
                    >
                        {items.map((item, i) => (
                            <List.Item key={i}>
                                <List.Content header={item.message}/>
                            </List.Item>
                        ))}
                    </Transition.Group>
                </Grid.Row>
            </Grid>
        </>
    );
}

export default BattleLog;
