import {combineReducers} from "redux";
import list from './listReducers';
import userCharacter from './userCharacterReducers';
import armor from './armorReducers';
import characterClass from './characterClassesReducers';
import weapon from './weaponReducers';

export default combineReducers({
  list,
  userCharacter,
  armor,
  characterClass,
  weapon,
});
