function isUserLoggedIn(duck) {
  return duck.user.isLoggedIn;
}

function userError(duck) {
  return duck.user.error;
}

function isUserLoading(duck) {
  return duck.user.loading;
}

function currentUserProfile(duck) {
  return duck.user.user;
}

function getToken(duck) {
  return duck.user.token;
};

export default {
  isUserLoggedIn,
  currentUserProfile,
  getToken,
  isUserLoading,
  userError,
}
