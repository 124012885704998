import './LoginForm.css';

import React from 'react';
import {Controller, useForm} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";
import {Button, Form, Input} from "semantic-ui-react";
import {uiHideModal} from "../../../common/ui-redux";
import {useDispatch} from "react-redux";



function RegistrationForm({data, saveCallback}) {

    const {register, control, handleSubmit, trigger, watch, formState: {errors}, setError, reset, setValue} = useForm({
        defaultValues: data,
    });
    const dispatch = useDispatch();

    const onFormSubmit = async (data) => {
        console.log(data);
        try {
            const result = await saveCallback(data);
            dispatch(uiHideModal());
        } catch (e) {
            console.log(e);
            const detailFieldErrors = e.fieldErrors;

            for (let item in detailFieldErrors) {
                if (detailFieldErrors.hasOwnProperty(item)) {
                    setError(item, {
                        type: 'server',
                        message: detailFieldErrors[item].join('. ')
                    })
                }
            }
        }
    };


    return (
        <>
            <Form onSubmit={handleSubmit(async (data) => await onFormSubmit(data))}>
                <Form.Field>
                    <Controller
                        name={"email"}
                        rules={{required: true}}
                        control={control}
                        render={({field: {onChange, onBlur, value, ref}}) => (
                            <Input type="text"
                                   label="E-mail"
                                   onChange={onChange}
                                   onBlur={onBlur}
                                   defaultValue={value}
                                   placeholder="email"
                                   error={errors.email ? true : false}
                            />
                        )}
                    />
                    <ErrorMessage errors={errors} name="email"/>
                </Form.Field>
                <Form.Field>
                    <Controller
                        name={"password1"}
                        rules={{required: true}}
                        control={control}
                        render={({field: {onChange, onBlur, value, ref}}) => (
                            <Input type="password"
                                   label="Password"
                                   onChange={onChange}
                                   onBlur={onBlur}
                                   defaultValue={value}
                                   placeholder="Password"
                                   error={errors.password1 ? true : false}
                            />
                        )}
                    />
                    <ErrorMessage errors={errors} name="password1"/>
                </Form.Field>
                <Form.Field>
                    <Controller
                        name={"password2"}
                        rules={{required: true}}
                        control={control}
                        render={({field: {onChange, onBlur, value, ref}}) => (
                            <Input type="password"
                                   label="Repeat password"
                                   onChange={onChange}
                                   onBlur={onBlur}
                                   defaultValue={value}
                                   placeholder="Repeat password"
                                   error={errors.password2 ? true : false}
                            />
                        )}
                    />
                    <ErrorMessage errors={errors} name="password2"/>
                </Form.Field>
                <Button className="float-right">Create account</Button>
            </Form>
        </>
    );
}

export default RegistrationForm;
