import React from 'react';
import {Button, Message} from "semantic-ui-react";
import {uiShowModal} from "../../../common/ui-redux";
import {dialogTypes} from "../../../common/components/modalDialogs";
import {useDispatch, useSelector} from "react-redux";
import {userSelectors} from "../duck";

function CheckUserLoginCard({message}) {

    const isUserLoggedIn = useSelector(userSelectors.isUserLoggedIn);
    const dispatch = useDispatch();

    return (
        <>
            {!isUserLoggedIn &&
            <>
                <Message>
                    {message}
                </Message>
                <div style={{flexDirection: "row"}}>
                    <Button id="loginButton"
                            onClick={() => dispatch(uiShowModal(dialogTypes.USER_LOGIN))}>Login</Button> or <Button
                    id="registerButton"
                    onClick={() => dispatch(uiShowModal(dialogTypes.USER_REGISTER))}>Register</Button>
                </div>
            </>
            }
        </>
    );
}

export default CheckUserLoginCard;
