import React, {Fragment} from 'react';

import PropTypes from 'prop-types';
import {ModalRoot} from "../components/modalDialogs";
import {Icon, Menu, Segment} from "semantic-ui-react";

import './PresentationLayout.css';
import UserMenu from "../../features/user/components/UserMenu";

const PresentationLayout = props => {
    const {children} = props;

    return <Fragment>
        <Segment attached='top' className={'mainSegment'}>{children}<ModalRoot/>
        </Segment>
        {/*TODO: uncomment this to enable user profile*/}
        {/*<UserMenu />*/}
    </Fragment>;
};

PresentationLayout.propTypes = {
    children: PropTypes.node
};

export default PresentationLayout;
