import React, {useCallback, useEffect, useState} from 'react';

import {useForm, Controller} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";
import {armorListRequest, characterClassListRequest, characterSelectors, weaponListRequest} from "../duck";
import {useDispatch, useSelector} from "react-redux";
import {Button, Form, FormGroup, Image, Input, Label, Select, TextArea} from "semantic-ui-react";
import CharacterImageEditor from "./CharacterImageEditor";
import {uiHideModal} from "../../../common/ui-redux";


function CharacterForm({isEdit, data, saveCallback}) {
    const {register, control, handleSubmit, trigger, watch, formState: {errors}, setError, reset, setValue} = useForm({
        defaultValues: data,
    });
    const classesList = useSelector(characterSelectors.characterClassList);
    const armorList = useSelector(characterSelectors.armorList);
    const weaponList = useSelector(characterSelectors.weaponList);

    const classListValues = classesList.map(i => ({key: i.id, value: i.id, text: i.name}));
    const armorListValues = armorList.map(i => ({key: i.id, value: i.id, text: `${i.name} (${i.value})`}));
    const weaponListValues = weaponList.map(i => ({key: i.id, value: i.id, text: `${i.name} (${i.value})`}));

    const dispatch = useDispatch();

    useEffect(() => {
        reset(data); // asynchronously reset your form values
    }, [data, classesList, armorList, weaponList]);

    const onFormSubmit = async (data) => {
        try {
            const result = await saveCallback({character: data});
            dispatch(uiHideModal());
        } catch (e) {
            const detailFieldErrors = e.fieldErrors;

            for (let item in detailFieldErrors) {
                if (detailFieldErrors.hasOwnProperty(item)) {
                    setError(item, {
                        type: 'server',
                        message: detailFieldErrors[item].join('. ')
                    })
                }
            }
        }
    };

    return (
        <>
            <Form onSubmit={handleSubmit(async (data) => await onFormSubmit(data))}>
                <Form.Field>
                    {isEdit &&
                    <Image centered src={data.image} wrapped ui={false} hasMasterSpinner={false}/>

                    }
                    {!isEdit &&
                    <Controller
                        name={"image"}
                        rules={{required: true}}
                        control={control}
                        render={({field: {onChange, onBlur, value, ref}}) => (
                            <CharacterImageEditor image={value} onChange={onChange}/>
                        )}
                    />
                    }
                </Form.Field>
                <Form.Field>
                    <Controller
                        name={"name"}
                        rules={{required: true}}
                        control={control}
                        render={({field: {onChange, onBlur, value, ref}}) => (
                            <Input type="text"
                                   label="Name"
                                   onChange={onChange}
                                   onBlur={onBlur}
                                   defaultValue={value}
                                   disabled={isEdit}
                                   placeholder="Character Name"
                                   error={errors.name ? true : false}
                            />
                        )}
                    />
                    <ErrorMessage errors={errors} name="name"/>
                </Form.Field>
                <Form.Field>
                    <Controller
                        name={"characterClass"}
                        rules={{required: true}}
                        control={control}
                        render={({field: {onChange, onBlur, value, ref}}) => (
                            <>
                                <label>Character class</label>
                                <Select
                                    label='Character class'
                                    placeholder='Select character class'
                                    id="characterClass"
                                    disabled={isEdit}
                                    options={classListValues}
                                    onChange={(_, data) => onChange(data.value)}
                                    defaultValue={value}
                                    error={errors.characterClass ? true : false}
                                />
                            </>
                        )}
                    />
                    <ErrorMessage errors={errors} name="characterClass"/>
                </Form.Field>
                <Form.Field>
                    <Controller
                        name={"level"}
                        rules={{required: true}}
                        control={control}
                        render={({field: {onChange, onBlur, value, ref}}) => (
                            <Input type="text"
                                   label="Level"
                                   onChange={onChange}
                                   onBlur={onBlur}
                                   defaultValue={value}
                                   placeholder="Character Name"
                                   disabled={true}
                                   error={errors.level ? true : false}
                            />
                        )}
                    />
                    <ErrorMessage errors={errors} name="level"/>
                </Form.Field>
                <Form.Field>
                    <Controller
                        name={"hp"}
                        rules={{required: true}}
                        control={control}
                        render={({field: {onChange, onBlur, value, ref}}) => (
                            <Input type="text"
                                   label="HP"
                                   onChange={onChange}
                                   onBlur={onBlur}
                                   defaultValue={value}
                                   disabled={true}
                                   error={errors.hp ? true : false}
                            />
                        )}
                    />
                    <ErrorMessage errors={errors} name="hp"/>
                </Form.Field>
                <Form.Field>
                    <Controller
                        name={"armor"}
                        rules={{required: true}}
                        control={control}
                        render={({field: {onChange, onBlur, value, ref}}) => (
                            <>
                                <label>Armor</label>
                                <Select
                                    label='Armor'
                                    placeholder='Select armor'
                                    id="armor"
                                    options={armorListValues}
                                    defaultValue={value}
                                    onChange={(_, data) => onChange(data.value)}
                                    error={errors.armor ? true : false}
                                />
                            </>
                        )}
                    />
                    <ErrorMessage errors={errors} name="armor"/>
                </Form.Field>
                <Form.Field>
                    <Controller
                        name={"weapon"}
                        rules={{required: true}}
                        control={control}
                        render={({field: {onChange, onBlur, value, ref}}) => (
                            <>
                                <label>Weapon</label>
                                <Select
                                    label='Weapons'
                                    placeholder='Select weapon'
                                    id="weapon"
                                    options={weaponListValues}
                                    defaultValue={value}
                                    onChange={(_, data) => onChange(data.value)}
                                    error={errors.weapon ? true : false}
                                />
                            </>
                        )}
                    />
                    <ErrorMessage errors={errors} name="weapon"/>
                </Form.Field>
                <Form.Field>
                    <Controller
                        name={"socialProfile"}
                        rules={{required: false}}
                        control={control}
                        render={({field: {onChange, onBlur, value, ref}}) => (
                            <Input type="text"
                                   label="Social profile"
                                   placeholder={"Social profile URL"}
                                   onChange={onChange}
                                   onBlur={onBlur}
                                   defaultValue={value}
                                   error={errors.socialProfile ? true : false}
                            />
                        )}
                    />
                    <ErrorMessage errors={errors} name="socialProfile"/>
                </Form.Field>
                <Form.Field>
                    <Controller
                        name={"description"}
                        rules={{required: false}}
                        control={control}
                        render={({field: {onChange, onBlur, value, ref}}) => (
                            <TextArea type="text"
                                      label="Description"
                                      onChange={onChange}
                                      onBlur={onBlur}
                                      defaultValue={value}
                                      error={errors.description ? true : false}
                            />
                        )}
                    />
                    <ErrorMessage errors={errors} name="socialProfile"/>
                </Form.Field>

                <Button data-testid="formCharacterSubmit" className="float-right">Save</Button>
            </Form>
        </>
    );
}

export default CharacterForm;