import React, {useContext, useEffect, useState} from 'react';
import {ButtonBack, ButtonNext, CarouselProvider, Slide, Slider, CarouselContext} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import SelectReporter from "./SelectReporter";

function PersonageSelector({list, onSelect, onClick}) {

    let  carouselContext = null;
    const setCarouselContext = (ctx) => {
        carouselContext = ctx;
    };
    const [currentPersonage, setCurrentPersonage] = useState(0);

    const onSlideChange = (index) => {
        setCurrentPersonage(index);
    }

    useEffect(() => {
        onSelect(list[currentPersonage])
    }, [currentPersonage]);

    useEffect(() => {
        onSelect(list[0]);
        carouselContext?.setStoreState({ currentSlide: 0 });
    }, [list]);


    const onSlideClick = (i) => {
        if (onClick) {
            onClick(list[i]);
        }
    }

    function ContextExtractor({setCtx}) {
        const carouselContext = useContext(CarouselContext);
        setCtx(carouselContext);
        return null;
    }

    return (
        <CarouselProvider
            naturalSlideWidth={1}
            naturalSlideHeight={1.25}
            isIntrinsicHeight={true}
            totalSlides={list.length}
            style={{width: "250px"}}
        >
            <ContextExtractor setCtx={setCarouselContext}/>
            <SelectReporter onSlideChange={onSlideChange}/>
            <Slider>
                {
                    list.map((item, i) => (
                        <Slide index={i}><img src={item.image} style={{ height: '250px', border: '1px solid #021a40'}} onClick={() => onSlideClick(i)}/>
                            <div style={{ fontWeight: 'bold' }}>{item.name}</div>
                        </Slide>
                    ))
                }
            </Slider>
            <ButtonBack>Back</ButtonBack>
            <ButtonNext>Next</ButtonNext>
        </CarouselProvider>
    );
}

export default PersonageSelector;
