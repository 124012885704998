function characterList(duck) {
  return duck.character.list.list;
}

function characterDetail(duck) {
  return duck.character.detail;
}

function isUserCharacterCreated(duck) {
  return duck.character.userCharacter.isCreated;
}

function isUserCharacterModified(duck) {
  return duck.character.userCharacter.isModified;
}

function userCharacter(duck) {
  return duck.character.userCharacter.character;
}

function characterClassList(duck) {
  return duck.character.characterClass.list
}

function weaponList(duck) {
  return duck.character.weapon.list
}

function armorList(duck) {
  return duck.character.armor.list
}

function isUserCharacterLoading(duck) {
  return duck.character.userCharacter.loading
}

function userCharacterErrors(duck) {
  return duck.character.userCharacter.errors;
}

export default {
  characterList,
  characterDetail,
  isUserCharacterCreated,
  isUserCharacterModified,
  isUserCharacterLoading,
  userCharacterErrors,
  userCharacter,
  characterClassList,
  weaponList,
  armorList,
}
