import React, {useEffect, useState} from 'react';
import {Button, Dimmer, Grid, Loader, Modal} from 'semantic-ui-react'
import {useDispatch, useSelector} from "react-redux";
// import {groupCreateRequest, groupGetRequest, groupSelectors, groupUpdateRequest} from "../../duck";
import {uiHideModal} from "../../../../common/ui-redux";
import {characterSelectors} from "../../duck";
import ReactMarkdown from "react-markdown";

function CharacterInfoDialog({character}) {

    const classesList = useSelector(characterSelectors.characterClassList);
    const armorList = useSelector(characterSelectors.armorList);
    const weaponList = useSelector(characterSelectors.weaponList);

    const dispatch = useDispatch();

    const toggle = () => {
        dispatch(uiHideModal());
    };


    const cancelClicked = () => {
        dispatch(uiHideModal());
    };

    const characterClass = classesList.find((i) => i.id === character.characterClass);
    const armor = armorList.find((i) => i.id === character.armor);
    const weapon = weaponList.find((i) => i.id === character.weapon);

    console.log(character);
    console.log(characterClass);

    return (
        <Modal open={true} closeIcon={true} onClose={toggle}>
            <Modal.Header toggle={toggle}>{character.name}</Modal.Header>
            <Modal.Content>
                <Grid container columns={2}>
                    <Grid.Column>
                        <img src={character.image} style={{height: '250px', border: '1px solid #021a40'}}/>
                    </Grid.Column>
                    <Grid.Column>
                        <p>Class: {characterClass.name}</p>
                        <p>Armor: {armor.name}</p>
                        <p>Armor class: {armor.value}</p>
                        <p>DamageL: {weapon.value}</p>
                        <p>HP: {character.hp}</p>
                        <p>level: {character.level}</p>
                        <p>weapon: {weapon.name}</p>
                        {character.socialProfile &&
                        <p>Social Media Profile: <a href={character.socialProfile} target={'_blank'}>{character.socialProfile}</a></p>
                        }
                        <ReactMarkdown>{character?.story}</ReactMarkdown>
                    </Grid.Column>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button color="olive" onClick={cancelClicked}>Return</Button>
            </Modal.Actions>
        </Modal>
    );
}

export default CharacterInfoDialog;
