import React from 'react';
import CharacterForm from "./CharacterForm";
import axios from 'axios';
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {promiseListener} from "../../../app/configureStore";
import MakeAsyncFunction from "react-redux-promise-listener";

import defaultProfileImage from '../assets/default_profile.png';
import {characterTypes} from "../duck";

function CharacterAddForm() {
    const history = useHistory();
    const dispatch = useDispatch();


    return (
        <MakeAsyncFunction
            listener={promiseListener}
            start={characterTypes.ADD_REQUEST}
            resolve={characterTypes.ADD_SUCCESS}
            reject={characterTypes.ADD_FAILURE}
        >{onSubmit => (
            <CharacterForm
                isEdit={false}
                data={{
                    id: 'user-unsaved',
                    name: '',
                    characterClass: '',
                    weapon: '',
                    armor: '',
                    level: 1,
                    image: defaultProfileImage,
                    hp: Math.floor(Math.random() * (20 - 5 + 1)) + 5
                }}
                saveCallback={onSubmit}/>
        )}
        </MakeAsyncFunction>
    );
}

export default CharacterAddForm;
