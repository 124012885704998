import React from 'react';
import CharacterForm from "./CharacterForm";
import axios from 'axios';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {promiseListener} from "../../../app/configureStore";
import MakeAsyncFunction from "react-redux-promise-listener";

import defaultProfileImage from '../assets/default_profile.png';
import {characterSelectors, characterTypes} from "../duck";

function CharacterEditForm() {
    const history = useHistory();
    const dispatch = useDispatch();

    const userCharacter = useSelector(characterSelectors.userCharacter);
    return (
        <MakeAsyncFunction
            listener={promiseListener}
            start={characterTypes.UPDATE_REQUEST}
            resolve={characterTypes.UPDATE_SUCCESS}
            reject={characterTypes.UPDATE_FAILURE}
        >{onSubmit => (
            <CharacterForm
                isEdit={true}
                data={userCharacter}
                saveCallback={onSubmit}/>
        )}
        </MakeAsyncFunction>
    );
}

export default CharacterEditForm;
