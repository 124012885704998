import React, {useEffect, useState} from 'react';
import {Button, Grid, Modal} from 'semantic-ui-react'
import {useDispatch, useSelector} from "react-redux";
import ReactMarkdown from 'react-markdown'
// import {groupCreateRequest, groupGetRequest, groupSelectors, groupUpdateRequest} from "../../duck";
import {uiHideModal} from "../../../../common/ui-redux";

function MonsterInfoDialog({monster}) {

    const dispatch = useDispatch();

    const toggle = () => {
        dispatch(uiHideModal());
    };


    const cancelClicked = () => {
        dispatch(uiHideModal());
    };

    return (
        <Modal open={true} closeIcon={true} onClose={toggle}>
            <Modal.Header toggle={toggle}>{monster.name}</Modal.Header>
            <Modal.Content>
                <Grid container columns={2}>
                    <Grid.Column>
                        <img src={monster.image} style={{height: '250px', border: '1px solid #021a40'}}/>
                    </Grid.Column>
                    <Grid.Column>
                        <p>Type: {monster.type}</p>
                        <p>Armor class: {monster.ac}</p>
                        <p>Damage: {monster.damage}</p>
                        <p>HD: {monster.hd}</p>
                        <p>HP: {monster.hp}</p>
                        <p>weapon: {monster.weaponName}</p>
                        <ReactMarkdown>{monster?.story}</ReactMarkdown>
                    </Grid.Column>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button color="olive" onClick={cancelClicked}>Return</Button>
            </Modal.Actions>
        </Modal>
    );
}

export default MonsterInfoDialog;
