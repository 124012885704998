import {getContext, put, takeLatest} from 'redux-saga/effects';
import {characterDeleteBegin, characterDeleteFailure, characterDeleteSuccess,} from '../actions';
import types from "../types";

function* deleteFolder(action) {
  try {
    const characterService = yield getContext("characterService");

    yield put(characterDeleteBegin());

    yield characterService.del();

    yield put(characterDeleteSuccess());
  } catch (e) {
    yield put(characterDeleteFailure(e.message));
  }
}

function* deleteSaga() {
  yield takeLatest(types.DELETE_REQUEST, deleteFolder);
}

export default deleteSaga;
