import {call, put, select, takeLatest, getContext} from 'redux-saga/effects';
import {
    monsterListBegin, monsterListSuccess, monsterListFailure
} from '../actions';

import types from '../types';

function* request(action) {
    try {
        const monsterService = yield getContext("monsterService");

        yield put(monsterListBegin());
        const listResponse = yield monsterService.getAll();
        yield put(monsterListSuccess(listResponse.data));
    } catch (e) {
        yield put(monsterListFailure(e.message));
    }
}

function* listSaga() {
    yield takeLatest(types.LIST_REQUEST, request);
}

export default listSaga;
