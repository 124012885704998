function monsterList(duck) {
  return duck.monster.list.list;
}

function monsterDetail(duck) {
  return duck.monster.detail;
}

export default {
  monsterList,
  monsterDetail,
}
