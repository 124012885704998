import axios from "axios";
import {API_PATH} from "../../config";

export const getAll = () => {
    return axios.get(`${API_PATH}/api/characters/`);
};

export const create = (character) => {
    return axios.post(`${API_PATH}/api/user_characters/`, character);
};

export const get = () => {
    return axios.get(`${API_PATH}/api/user_characters/`);
};

export const del = () => {
    return axios.delete(`${API_PATH}/api/user_characters/character/`);
};

export const update = (armor, weapon, description, socialProfile) => {
    return axios.put(`${API_PATH}/api/user_characters/character/`, {
        armor,
        weapon,
        description,
        socialProfile,
    });
};

export const getCharacterClasses = () => {
    return axios.get(`${API_PATH}/api/character_classes/`);
};

export const getArmors = () => {
    return axios.get(`${API_PATH}/api/armors/`);
};

export const getWeapons = () => {
    return axios.get(`${API_PATH}/api/weapons/`);
};
