const LOGIN_REQUEST = '/features/user/LOGIN_REQUEST';
const LOGIN_BEGIN = '/features/user/LOGIN_BEGIN';
const LOGIN_SUCCESS = '/features/user/LOGIN_SUCCESS';
const LOGIN_FAILURE = '/features/user/LOGIN_FAILURE';

const REGISTRATION_REQUEST = '/features/user/REGISTRATION_REQUEST';
const REGISTRATION_BEGIN = '/features/user/REGISTRATION_BEGIN';
const REGISTRATION_SUCCESS = '/features/user/REGISTRATION_SUCCESS';
const REGISTRATION_FAILURE = '/features/user/REGISTRATION_FAILURE';

const TOKEN_REFRESH_REQUEST = '/features/user/TOKEN_REFRESH_REQUEST';
const TOKEN_REFRESH_BEGIN = '/features/user/TOKEN_REFRESH_BEGIN';
const TOKEN_REFRESH_SUCCESS = '/features/user/TOKEN_REFRESH_SUCCESS';
const TOKEN_REFRESH_FAILURE = '/features/user/TOKEN_REFRESH_FAILURE';

const LOGOUT_REQUEST = '/features/user/LOGOUT_REQUEST';
const LOGOUT_BEGIN = '/features/user/LOGOUT_BEGIN';
const LOGOUT_SUCCESS = '/features/user/LOGOUT_SUCCESS';
const LOGOUT_FAILURE = '/features/user/LOGOUT_FAILURE';

const CURRENT_USER_INFO_REQUEST = '/features/user/CURRENT_USER_INFO_REQUEST';
const CURRENT_USER_INFO_BEGIN = '/features/user/CURRENT_USER_INFO_BEGIN';
const CURRENT_USER_INFO_SUCCESS = '/features/user/CURRENT_USER_INFO_SUCCESS';
const CURRENT_USER_INFO_FAILURE = '/features/user/CURRENT_USER_INFO_FAILURE';

const AVATAR_SAVE_REQUEST = '/features/user/AVATAR_SAVE_REQUEST';
const AVATAR_SAVE_BEGIN = '/features/user/AVATAR_SAVE_BEGIN';
const AVATAR_SAVE_SUCCESS = '/features/user/AVATAR_SAVE_SUCCESS';
const AVATAR_SAVE_FAILURE = '/features/user/AVATAR_SAVE_FAILURE';

const PROFILE_UPDATE_REQUEST = '/features/user/PROFILE_UPDATE_REQUEST';
const PROFILE_UPDATE_BEGIN = '/features/user/PROFILE_UPDATE_BEGIN';
const PROFILE_UPDATE_SUCCESS = '/features/user/PROFILE_UPDATE_SUCCESS';
const PROFILE_UPDATE_FAILURE = '/features/user/PROFILE_UPDATE_FAILURE';

export default {
  LOGIN_REQUEST,
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,

  REGISTRATION_REQUEST,
  REGISTRATION_BEGIN,
  REGISTRATION_SUCCESS,
  REGISTRATION_FAILURE,

  TOKEN_REFRESH_REQUEST,
  TOKEN_REFRESH_BEGIN,
  TOKEN_REFRESH_SUCCESS,
  TOKEN_REFRESH_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_BEGIN,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,

  CURRENT_USER_INFO_REQUEST,
  CURRENT_USER_INFO_BEGIN,
  CURRENT_USER_INFO_SUCCESS,
  CURRENT_USER_INFO_FAILURE,

  AVATAR_SAVE_REQUEST,
  AVATAR_SAVE_BEGIN,
  AVATAR_SAVE_SUCCESS,
  AVATAR_SAVE_FAILURE,

  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_BEGIN,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAILURE,
};
