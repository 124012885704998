import React, {useContext, useEffect} from 'react';
import {CarouselContext} from "pure-react-carousel";

function SelectReporter({onSlideChange}) {
    const carouselContext = useContext(CarouselContext);

    useEffect(() => {
        if (carouselContext) {
            function onChange() {
                onSlideChange(carouselContext.state.currentSlide);
            }

            carouselContext.subscribe(onChange);
            return () => carouselContext.unsubscribe(onChange);
        }
    }, [carouselContext]);

    return (
        <></>
    );
}

export default SelectReporter;
