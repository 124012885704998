import {getContext, put, takeLatest} from 'redux-saga/effects';
import {armorListBegin, armorListFailure, armorListSuccess} from '../actions';

import types from '../types';

function* request(action) {
    try {
        const characterService = yield getContext("characterService");

        yield put(armorListBegin());
        const listResponse = yield characterService.getArmors();
        yield put(armorListSuccess(listResponse.data));
    } catch (e) {
        yield put(armorListFailure(e.message));
    }
}

function* armorListSaga() {
    yield takeLatest(types.ARMOR_LIST_REQUEST, request);
}

export default armorListSaga;
