import axios from 'axios';
import {userLogoutSuccess, userTokenRefreshSuccess} from "../features/user/duck";
import {API_PATH} from "../config";
import * as userService from '../common/api/userService';

export function createAxiosAuthMiddleware() {
    let axiosSetup = false;
    return ({ getState, dispatch }) => next => (action) => {

        // axios.defaults.headers.common.Authorization = token ? `Bearer ${token}` : null;
        if (axiosSetup === false ) {
            // console.log('initialize axios');
            axios.interceptors.request.use(
                config => {
                    const token = getState().user.token;
                    // const token = token;
                    if (token) {
                        config.headers['Authorization'] = 'Bearer ' + token;
                    }
                    return config;
                },
                error => {
                    Promise.reject(error)
                });

            axios.interceptors.response.use((response) => {
                    return response
                },
                async (error) => {
                    const originalRequest = error.config;
                    // console.log(error);
                    // console.log(error?.response?.status);
                    // console.log(originalRequest.url);
                    // console.log(originalRequest);
                    if (error?.response?.status === 401 && originalRequest.url ===
                        `${API_PATH}/auth/token/refresh/`) {
                        // router.push('/login');
                        // todo: вывести сообщение пользователю чего вообще произошло
                        dispatch(userLogoutSuccess());
                        return Promise.reject(error);
                    }

                    if (error?.response?.status === 500 && originalRequest.url ===
                        `${API_PATH}/auth/token/refresh/`) {
                        // router.push('/login');
                        // todo: вывести сообщение пользователю чего вообще произошло
                        dispatch(userLogoutSuccess());
                        return Promise.reject(error);
                    }


                    // console.log('continue with retry');
                    if (error?.response?.status === 401 && originalRequest._retry !== true) {
                        // console.log('initialize refresh request');
                        originalRequest._retry = true;

                        const tokenResponse  = await userService.tokenRefresh(
                            getState().user.refreshToken
                        );
                        // console.log('refresh response');
                        // console.log(tokenResponse);
                        if (tokenResponse?.status === 201 || tokenResponse?.status === 200) {
                            // 1) put token to LocalStorage
                            dispatch(userTokenRefreshSuccess(tokenResponse.data.access))

                            // 2) Change Authorization header
                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenResponse.data.access;

                            // 3) return originalRequest object with Axios.
                            return axios(originalRequest);
                        }
                    }
                    return Promise.reject(error);
                });
            axiosSetup = true;
        }

        return next(action);
    };
}