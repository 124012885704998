import './LoginForm.css';

import React, {useEffect, useState} from 'react';
import {Controller, useForm, useFormState} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";
import {Button, Form, Input} from "semantic-ui-react";
import {useDispatch} from "react-redux";
import {uiHideModal, uiShowModal} from "../../../common/ui-redux";
import {dialogTypes} from "../../../common/components/modalDialogs";
import {GoogleLogin} from 'react-google-login';


function LoginForm({data, saveCallback}) {

    const dispatch = useDispatch();

    const [formError, setFormError] = useState('');

    const {
        register,
        control,
        handleSubmit,
        trigger,
        watch,
        formState: {errors},
        setError,
        clearErrors,
        setValue
    } = useForm({
        defaultValues: data,
    });

    const responseGoogle = (response) => {
        console.log(response);
    }

    const onRegisterClick = (e) => {
        e.preventDefault();
        dispatch(uiShowModal(dialogTypes.USER_REGISTER));
    };

    const onFormSubmit = async (data) => {
        try {
            const result = await saveCallback(data);
            dispatch(uiHideModal());
        } catch (e) {
            const detailFieldErrors = e.fieldErrors;
            for (let item in detailFieldErrors) {
                if (detailFieldErrors.hasOwnProperty(item)) {
                    setError(item, {
                        type: 'server',
                        message: detailFieldErrors[item].join('. ')
                    })
                }
            }

            if (detailFieldErrors.hasOwnProperty('nonFieldErrors')) {
                setFormError(detailFieldErrors['nonFieldErrors'].join('. '));
                clearErrors('nonFieldErrors');
            }
        }
    };

    return (
        <>
            <Form onSubmit={handleSubmit(async (data) => await onFormSubmit(data))}>
                {formError}
                <Form.Field>
                    <Controller
                        name={"email"}
                        rules={{required: true}}
                        control={control}
                        render={({field: {onChange, onBlur, value, ref}}) => (
                            <Input type="text"
                                   label="E-mail"
                                   onChange={onChange}
                                   onBlur={onBlur}
                                   defaultValue={value}
                                   placeholder="email"
                                   error={errors.email ? true : false}
                            />
                        )}
                    />
                    <ErrorMessage errors={errors} name="email"/>
                </Form.Field>
                <Form.Field>
                    <Controller
                        name={"password"}
                        rules={{required: true}}
                        control={control}
                        render={({field: {onChange, onBlur, value, ref}}) => (
                            <Input type="password"
                                   label="Password"
                                   onChange={onChange}
                                   onBlur={onBlur}
                                   defaultValue={value}
                                   placeholder="Password"
                                   error={errors.password ? true : false}
                            />
                        )}
                    />
                    <ErrorMessage errors={errors} name="password"/>
                </Form.Field>


                <Button className="float-right">Login</Button> or <a href={"#"} onClick={onRegisterClick}>Register</a>
                {/*<div style={{marginTop: 20}}>*/}
                {/*    <GoogleLogin*/}
                {/*        clientId="455462437665-bppep52jrhnookhqim1338r9c0n0pfcf.apps.googleusercontent.com"*/}
                {/*        buttonText="Login with Google"*/}
                {/*        onSuccess={responseGoogle}*/}
                {/*        onFailure={responseGoogle}*/}
                {/*        cookiePolicy={'single_host_origin'}*/}
                {/*    />*/}
                {/*</div>*/}
            </Form>
        </>
    );
}

export default LoginForm;
