import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import CharacterSelector from "../../features/character/components/CharacterSelector";
import {Button, Grid, Message} from "semantic-ui-react";
import MonsterSelector from "../../features/monster/components/MonsterSelector";
import {battleListRequest, battleSelectors, setBattleFinishedStatus} from "../../features/battle/duck";
import './BattlePage.css'
import {characterCreateRequest, characterSelectors} from "../../features/character/duck";
import BattleStat from "../../features/battle/components/BattleStat";
import BattleLog from "../../features/battle/components/BattleLog";
import {userSelectors} from "../../features/user/duck";
import {uiShowModal} from "../../common/ui-redux";
import {dialogTypes} from "../../common/components/modalDialogs";
import CheckUserLoginCard from "../../features/user/components/CheckUserLoginCard";
import PromoAddForm from "../../features/promo/components/PromoAddForm";
import {promoSelectors} from "../../features/promo/duck";

function BattlePage(props) {

    const dispatch = useDispatch();

    const [character, setCharacter] = useState({});
    const [monster, setMonster] = useState({});
    const [message, setMessage] = useState('Press battle to fight!');
    const [fightIsActive, setFightIsActive] = useState(false);

    const isBattleWon = useSelector(battleSelectors.isBattleWon);
    const isPromoActive = useSelector(battleSelectors.isPromoActive);
    const isPromoSent = useSelector(promoSelectors.isPromoSent);
    const isBattleFinished = useSelector(battleSelectors.isBattleFinished);
    const isCharacterCreated = useSelector(characterSelectors.isUserCharacterCreated);
    const isCharacterModified = useSelector(characterSelectors.isUserCharacterModified);
    const isUserLoggedIn = useSelector(userSelectors.isUserLoggedIn);

    const selectCharacter = (ch) => {
        setCharacter(ch);
    };

    const selectMonster = (m) => {
        setMonster(m);
    };

    const startFight = () => {
        setMessage(`${character.name} vs ${monster.type} now are fighting`);
        dispatch(battleListRequest(character, monster.id))
        setFightIsActive(true);
        dispatch(setBattleFinishedStatus(false));
    };

    const saveCharacter = () => {
        dispatch(characterCreateRequest(character));
    }

    return (
        <Grid>
            <Grid.Row verticalAlign={'middle'} centered>
                <CharacterSelector onSelect={selectCharacter}/>
                <div style={{'height': '250px', 'lineHeight': '250px', 'margin': '0 50px'}}>&nbsp;VS&nbsp;</div>
                <MonsterSelector onSelect={selectMonster}/>
            </Grid.Row>
            <Grid.Row centered>
                <Button id="battleButton" onClick={startFight}>Battle!</Button>
            </Grid.Row>
            {isBattleWon && isPromoActive &&
            <Grid.Row centered>
                {!isPromoSent &&
                <div style={{'flexDirection': 'column'}}>
                    <p>Congratulations Fine Warrior! You have defeated this foul, unwholesome beast!</p>
                    <p>Enter name and email for your chance to win a $20.00 Gift Certificate from Paula's Pixels
                        (Drawing 1/1/2022) </p>
                    <PromoAddForm/>
                </div>
                }
                {isPromoSent && <p>Your email has been saved!</p>}
            </Grid.Row>
            }
            {/*TODO: uncomment this to enable character saving*/}
            {/*{isBattleWon && isCharacterCreated && isCharacterModified && isBattleFinished &&*/}
            {/*<Grid.Row centered style={{flexDirection: "column"}}>*/}
            {/*    <Message>*/}
            {/*        You won the battle, and now you can save your character!*/}
            {/*    </Message>*/}
            {/*    {isUserLoggedIn &&*/}
            {/*    <div style={{flexDirection: "row"}}>*/}
            {/*        <Button id="saveButton" onClick={saveCharacter}>Save character</Button>*/}
            {/*    </div>*/}
            {/*    }*/}
            {/*    <CheckUserLoginCard message={"You need to login or register to save your character"}/>*/}
            {/*</Grid.Row>*/}
            {/*}*/}
            <Grid.Row style={{fontWeight: 'bold'}} centered>
                {message}
            </Grid.Row>
            <Grid.Row centered>
                {fightIsActive && <BattleStat/>}
            </Grid.Row>
            <Grid.Row centered className={'wide'}>
                {fightIsActive && <BattleLog character={character} monster={monster}/>}
            </Grid.Row>
        </Grid>
    );
}

export default BattlePage;
