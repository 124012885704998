import {all} from '@redux-saga/core/effects';
import {battleSagas} from "../features/battle/duck";
import {monsterSagas} from "../features/monster/duck";
import {characterSagas} from "../features/character/duck";
import {userSagas} from '../features/user/duck';
import {promoSagas} from "../features/promo/duck";

export default function* rootSaga() {
    yield all([
        battleSagas(),
        monsterSagas(),
        characterSagas(),
        userSagas(),
        promoSagas(),
    ]);
    // code after all-effect
}
